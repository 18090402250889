.hero {
    position: relative;
    background-color: map-deep-get($colors, "gray", "background-light");
    padding-top: 20px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
    }

    &__wrapper {
        width: 100%;
        padding-top: 42.857%;
        position: relative;
    }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__fetchimage-wrapper {
        height: 617px;
        width: 100%;
        position: relative;
        z-index: -1;
        top: -100px;
        margin-bottom: -100px;
    }
}
