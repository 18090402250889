.home-intro {
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 3rem;
    background-color: map-deep-get($colors, "gray", "background-light");

    @include media-breakpoint-up(md) {
        padding-bottom: 5rem;
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__content {
        font-size: 18px;
        text-align: center;
    }
}
