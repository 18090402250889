.page-intro {
    &__link {
        display: inline-block;
        margin-bottom: 1em;
        color: map-deep-get($colors, "brand", "secondary");

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }

        &:hover {
            color: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__title {
        margin-top: 0;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &__subtitle {
        color: map-deep-get($colors, "brand", "primary");
    }
}
