.navigation {
    padding: 20px 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    $elm: &;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-left: 10px;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        width: auto;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 30px;
    }

    &--inverted {
        #{$elm}__item {
            @include media-breakpoint-up(md) {
                color: map-deep-get($colors, "white", "default");
                text-shadow: $box-shadow-normal;
            }
        }
    }

    &--scrolled {
        #{$elm}__item {
            color: map-deep-get($colors, "brand", "secondary");
            text-shadow: none;

            &--active {
                position: relative;
                color: map-deep-get($colors, "brand", "primary");
            }

            &--active::before {
                @include media-breakpoint-up(xl) {
                    top: -15px;
                }
            }
        }
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: visible;
        }

        &--active {
            color: map-deep-get($colors, "brand", "primary");
            transition: all ease-in-out 0s 0s;
            margin-top: -#{map-get($header-height, "small")};
            padding-top: #{map-get($header-height, "small")};
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        display: block;
        position: relative;
        padding: 10px 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0 8px;
            opacity: 1;
            display: inline-block;

            &--has-sub:hover {
                #{$elm}__item {
                    background: map-deep-get($colors, "white", "default");
                    color: map-deep-get($colors, "brand", "secondary");
                    opacity: 0.5;
                    margin-top: 10px;
                    padding-bottom: 15px;
                }

                #{$elm}__list--sub {
                    display: block;

                    #{$elm}__item {
                        color: map-deep-get($colors, "black", "default");
                        margin: 0;
                        padding: 5px 0;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            margin: 0 15px;
        }
    }

    &__item {
        display: inline-block;
        font-family: $font-family-headings;
        font-size: rem-calc(24);
        font-weight: 900;
        line-height: 1.25em;
        color: map-deep-get($colors, "brand", "secondary");
        padding: 5px;
        white-space: nowrap;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: rem-calc(16);
        }

        &--active {
            color: map-deep-get($colors, "brand", "primary");

            @include media-breakpoint-up(md) {
                color: map-deep-get($colors, "brand", "primary") !important;
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: map-deep-get($colors, "brand", "secondary");
            opacity: 0.5;
        }
    }

    &__list--sub {
        display: block;
        padding: 8px;

        #{$elm}__list-item {
            display: block;
            width: 100%;
        }

        #{$elm}__item {
            font-weight: 600;
            line-height: 1.5em;
            font-size: rem-calc(16);
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "black", "default");
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }
}
