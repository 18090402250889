.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    overflow: hidden;
    $header: &;

    @include media-breakpoint-up(md) {
        margin: 0 auto;
        height: map_get($header-height, "medium");
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--inverted {
        background: transparent;

        #{$header}__logo {
            svg {
                fill: map-deep-get($colors, "white", "default");
                filter: drop-shadow($box-shadow-normal);
            }
        }

        #{$header}__button {
            box-shadow: $box-shadow-normal;
        }
    }

    &--scrolled {
        background: map-deep-get($colors, "white", "default");
        box-shadow: $box-shadow-normal;
        margin-top: 0;

        #{$header}__logo {
            svg {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }

        @include media-breakpoint-up(md) {
            height: map_get($header-height, "medium--scrolled");
        }

        @include media-breakpoint-up(xl) {
            #{$header}__logo {
                height: map_get($header-height, "medium--scrolled");
            }
        }

        #{$header}__button {
            box-shadow: none;
        }
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &--scrolled,
    &--expanded {
        #{$header}__logo {
            /* stylelint-disable */
            svg #dark path {
                fill: map-deep-get($colors, "brand", "secondary");
                box-shadow: none;
            }
            /* stylelint-enable */

            svg {
                filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
            }
        }
    }

    &__logo {
        transition: $transition-default;
        height: 45px;
        width: 200px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
            height: 56px;
            width: 200px;
        }

        @include media-breakpoint-up(xl) {
            height: 69px;
            width: 280px;
        }

        svg {
            width: 100%;
            height: auto;
            transition: $transition-default;
        }

        svg path {
            transition: $transition-default;
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }
}
