.surface-shape {
    svg {
        width: 100%;
        height: 162px;
        margin-bottom: -1px;
    }

    svg path {
        fill: map-deep-get($colors, "brand", "secondary");
    }

    &--flip {
        transform: scale(-1);
    }

    &--footer {
        svg {
            height: 82px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary-light");
        }
    }
}