.home-about-us {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: map-deep-get($colors, "white", "default");
    background: map-deep-get($colors, "brand", "secondary");
    position: relative;

    @include media-breakpoint-up(md) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    &__inner {
        position: absolute;
        top: -1.5rem;
        bottom: -1.5rem;
        left: 1.5rem;
        right: 1.5rem;
        border: 2px solid map-deep-get($colors, "gray", "dark");
        font-size: rem-calc(18);

        @include media-breakpoint-up(md) {
            top: -3rem;
            bottom: -3rem;
            left: 3rem;
            right: -3rem;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 280px;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 20px;
            background-color: map-deep-get($colors, "brand", "secondary");
            z-index: 1;

            @include media-breakpoint-down(md) {
                left: 50%;
            }
        }

        &::after {
            content: "";
            position: absolute;
            background-image: url("../images/template/icons/like.svg");
            background-position: center;
            background-size: cover;
            display: block;
            top: 0;
            left: 280px;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            z-index: 2;

            @include media-breakpoint-down(md) {
                left: 50%;
            }
        }
    }

    &__content-wrapper {
        position: relative;
    }

    &__content {
        background: map-deep-get($colors, "brand", "secondary");
        padding-top: 1.25rem;
        padding-bottom: 1.26rem;
        position: relative;
    }

    &__overview__link {
        color: map-deep-get($colors, "brand", "primary");

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }
}
