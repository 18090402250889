.home-examples {
    padding: 5rem 0;

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__content {
        font-size: 18px;
        margin-bottom: 3em;
    }

    &__overview-link {
        color: map-deep-get($colors, "brand", "primary");
        float: right;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }
}
