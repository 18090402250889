.main {
    max-width: 100%;
    padding-top: 3.5rem;
    padding-bottom: 5rem;
    transition: $transition-default;
    flex: 1 1 100%;

    @include media-breakpoint-up(md) {
        padding-top: 0;
    }

    &__nav-margin {
        @include media-breakpoint-up(md) {
            margin-top: 100px;
        }
    }
}
