.button {
    padding: 0.75em 1.25em;
    min-height: rem-calc(48);
    line-height: 1.25em;
    font-size: rem-calc(16);
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    border-radius: $border-radius;
    transition: $transition-default;
    font-weight: 900;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
        color: map-deep-get($colors, "white", "default");
        box-shadow: -2px 2px 7px rgba(map-deep-get($colors, "black", "default"), .2);
    }

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: 1rem;
        vertical-align: middle;
        transition: $transition-default;
        fill: map-deep-get($colors, "white", "default");
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--small {
        font-size: rem-calc(14);
        min-height: rem-calc(38);

        svg {
            width: rem-calc(14);
            height: rem-calc(14);
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover,
            &:focus {
                background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
            }
        }
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover,
            &:focus {
                background-color: darken(map-deep-get($colors, "brand", "secondary"), 10%);
            }
        }
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "white", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover,
            &:focus {
                background-color: lighten(map-deep-get($colors, "black", "default"), 10%);
            }
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover,
            &:focus {
                background: map-deep-get($colors, "gray", "light");
            }
        }
    }
}