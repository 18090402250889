.footer {
    color: map-deep-get($colors, "black", "default");
    position: relative;
    z-index: 15;
    padding-top: 2.5rem;
    $elm: &;
    padding-bottom: 30px;
    font-size: rem-calc(14);
    background: map-deep-get($colors, "brand", "primary-light");

    @include media-breakpoint-up(md) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    &__top {
        margin-bottom: 2rem;
    }

    &__cta {
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-bottom: 2em;
        height: 100%;
    }

    &__title {
        margin-top: .8em;
        color: map-deep-get($colors, "brand", "secondary");

        &--form {
            margin-top: 0;
        }
    }

    &__content {
        font-size: 18px;
    }

    &__row {
        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: center;
        }
    }

    &__form {
        padding: 32px;
        border-radius: $border-radius;
        box-shadow: $box-shadow-medium;
        margin-bottom: 5rem;
        margin-top: 5rem;

        /* stylelint-disable */
        // different form styling for footer form
        .form {
            display: flex;
            flex-wrap: wrap;
            margin: 0 #{0 - $grid-gutter-width / 2};

            &__input--text,
            &__input--email,
            &__input--textarea,
            &__input--number {
                padding-top: 10px;

                &:focus,
                &[data-state="filled"] {
                    + .form__label--compact {
                        font-size: 12px;
                        top: 5px;
                    }
                }

                &:-webkit-autofill {
                    + .form__label--compact {
                        font-size: 12px;
                        top: 5px;
                    }
                }
            }

            &__group {
                flex: 0 0 100%;
                padding: 0 #{$grid-gutter-width / 2};
                max-width: 100%;

                &:nth-child(5),
                &:nth-child(6) {
                    @include media-breakpoint-up(md) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                > .form__label {
                    display: none;

                    &--compact {
                        display: block;
                        position: absolute;
                        top: 10px;
                        left: 26px;
                        pointer-events: none;
                        font-weight: 400;
                        color: map-deep-get($colors, "gray", "medium");
                        transition: all .1s $transition-default-smoothing;
                    }
                }
            }

            &__input--textarea {
                padding-top: 16px;
            }
        }
        /* stylelint-enable */
    }

    &__logo {
        height: 100%;

        @include media-breakpoint-down(md) {
            text-align: center;
            padding-bottom: 2rem;
        }

        @include media-breakpoint-down(sm) {
            svg {
                width: 90%;
            }
        }
    }

    &__list {
        margin-top: 3em;
        margin-bottom: 1em;
        text-align: left;
        display: inline-block;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: 0;
            height: 100%;
            display: flex;
            flex-flow: column;
        }

        &--horizontal {
            margin-top: 0;

            @include media-breakpoint-up(md) {
                display: inline-block;
                height: auto;
            }

            #{$elm}__list-item {
                @include media-breakpoint-up(md) {
                    display: inline;
                }
            }

            #{$elm}__link {
                color: map-deep-get($colors, "brand", "primary-medium");
                border-color: transparent;
            }

            #{$elm}__link:hover {
                border-color: map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &__list-item {
        display: flex;
        align-items: flex-start;
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-weight: 700;
        color: map-deep-get($colors, "brand", "secondary");
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: 0;
            padding-right: 10px;

            &:last-child::after {
                content: none;
            }
        }

        svg {
            width: 1em;
            height: 1em;
            margin-top: .3em;
            margin-right: .5em;
        }

        &__socials {
            svg {
                width: 2em;
                height: 2em;
                margin-top: .5em;
                margin-right: .75em;
                cursor: pointer;
            }
        }
    }

    &__list-item-pb {
        @include media-breakpoint-up(md) {
            padding-bottom: 0.75rem;
        }
    }

    &__list-item-contact {
        font-weight: 100;
    }

    &__link {
        display: inline-block;
        margin-bottom: .2em;
        height: 1.2em;
        text-decoration: none;
        color: inherit;
        border-bottom: 1px solid map-deep-get($colors, "brand", "secondary");
        transition: $transition-default;

        &:hover {
            margin-bottom: 0;
            height: 1.4em;
            text-decoration: none;
            color: map-deep-get($colors, "brand", "primary");
            border-bottom: 2px solid map-deep-get($colors, "brand", "primary");
        }
    }

    &__contact-details {
        width: 100%;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__spacer {
        padding-right: 1rem;
    }

    &__main-brand {
        margin: 2em 0;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
            height: 100%;
            margin: 0;
            float: right;
        }
    }

    &__main-brand-label {
        font-weight: 700;

        @include media-breakpoint-up(md) {
            display: inline-block;
            max-width: 100px;
            margin-right: 20px;
        }
    }

    &__copyright {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary-medium");
        float: left;
    }

    &__reference {
        font-size: 14px;
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary-medium");
        -webkit-font-smoothing: antialiased;
        float: right;
        text-align: end;

        &__link {
            color: map-deep-get($colors, "brand", "primary-medium");
        }

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: auto;
            float: right;
        }
    }

    &__webnl {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
        transition: $transition-default;

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
            text-decoration: underline;
        }
    }

    &__bottom {
        width: 100%;
        display: flex;
        background-color: map-deep-get($colors, "brand", "primary");
        padding: 1rem 1rem 0.6rem;
    }

    &__opening-info {
        font-family: $font-family-headings;
        font-size: 1rem;
        font-weight: 700;

        &__days {
            color: map-deep-get($colors, "brand", "secondary");
        }

        &__hours {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__list__policies {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}

footer {
    flex: 1 1 100%;
}
