.home-quality {
    text-align: center;
    position: relative;
    color: map-deep-get($colors, "white", "default");

    &__image {
        background-size: cover;
    }

    &__image-overlay {
        position: absolute;
        background-color: map-deep-get($colors, "brand", "primary");
        height: 100%;
        width: 100%;
        opacity: .9;
    }

    &__content {
        padding: 7.5rem 0;
        z-index: 2;
    }
}
