.page-cta {
    margin-top: 2em;
    padding: 32px;
    border-radius: $border-radius;
    box-shadow: $box-shadow-medium;
    background: map-deep-get($colors, "brand", "secondary");

    @include media-breakpoint-up(md) {
        margin-top: -5em;
        position: sticky;
        top: 7.5rem;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 1em;
        color: map-deep-get($colors, "white", "default");
    }
}
