﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: $font-family-headings;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    &--cursive {
        font-weight: 400;
        font-style: italic;
    }
}

p {
    margin-top: 0;
    line-height: 1.5;
}

.intro {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
}

h1, .h1 {
    font-size: rem-calc(40);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(50);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(60);
    }
}

h2, .h2 {
    font-size: 2.25rem;

    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }
}

h3, .h3 {
    font-size: 2rem;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(40);
    }
}

h4, .h4 {
    font-size: rem-calc(28);

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(32);
    }
}

h5, .h5 {
    font-size: rem-calc(24);
}

h6, .h6 {
    font-size: rem-calc(18);
}

strong {
    font-weight: 900;
}
