.text-link {
    font-family: brandon-grotesque, sans-serif;
    font-weight: 900;
    color: map-deep-get($colors, "brand", "primary");

    &:hover,
    &:focus {
        color: map-deep-get($colors, "brand", "primary");
        text-decoration: none;
    }

    &--icon-right {
        svg {
            margin-right: 0;
            margin-left: .5em;
            transition: $transition-default;
        }

        &:hover {
            svg {
                margin-right: -.25em;
                margin-left: .75em;
            }
        }
    }

    svg {
        width: 20px;
        height: 20px;
        fill: map-deep-get($colors, "brand", "primary");
    }
}